body, html, #root, .App {
  background-color: #f6f7fb; /* Choose your own interesting color */
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Define the background color */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

/* Style for the SVG */
.loading-svg {
  width: 135px;
  height: 135px;
}

.version-info {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px;
}

.dialog-content-container {
  position: relative;
}
